const Footer = () => {
  return (
    <footer
      role='contentinfo'
      className='nhsuk-width-container nhsuk-u-margin-bottom-5 nhsuk-u-margin-top-5 flex justify-center'>
      <div>
        Environment - <span id='current-environment'>{process.env.REACT_APP_ENVIRONMENT}</span>
      </div>
      <div className='nhsuk-u-margin-left-9 nhsuk-u-margin-right-9'>
        Version - <span id='current-version'>{process.env.REACT_APP_VERSION}</span>
      </div>
      <div>
        <a href='https://sites.google.com/nihr.ac.uk/crncc-policies/cpms' target='_blank' rel='noreferrer'>
          CPMS Policies
        </a>
      </div>
    </footer>
  )
}

export default Footer
